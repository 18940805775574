import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import PropTypes from 'prop-types';
import './/../Global/shared-css.css';
import LoadingSceen from './Loading/Loading';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import metacoreSvg from '../Images/metacore.svg';

const firebaseConfig = {
    apiKey: "AIzaSyCXxNVxVEYMUPP1oF-aatik7-yrKOZk8dY",
    authDomain: "gonugmm.firebaseapp.com",
    databaseURL: "https://gonugmm-default-rtdb.firebaseio.com",
    projectId: "gonugmm",
    storageBucket: "gonugmm.appspot.com",
    messagingSenderId: "1012307687730",
    appId: "1:1012307687730:web:6f5faa7be878fec826bf2b",
    measurementId: "G-Y4YTBW7HJZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function checkIpApproval(url, ip) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ip }),
            timeout: 20000,
        });

        if (response.ok) {
            const approvalData = await response.json();
            return approvalData.status ?? 'denied';
        } else if (response.status === 403) {
            return 'denied';
        } else {
            return 'error';
        }
    } catch (error) {
        console.error('Approval API Error:', error);
        return 'error';
    }
}

function getClientIp() {
    return fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip)
        .catch(error => {
            console.error('Error fetching IP:', error);
            return 'UNKNOWN';
        });
}

function MaskProtectedPage() {
    const [redirectUrl, setRedirectUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const urlParams = new URL(window.location.href).searchParams;
    const next = urlParams.get('next');

    useEffect(() => {
        const fetchApprovalStatus = async () => {
            const userIp = await getClientIp();
            const approvalApiUrl = "https://config.metaservices.online/api/ip/approval";
            const approvalStatus = await checkIpApproval(approvalApiUrl, userIp);

            if (approvalStatus === 'approved') {
                const docRef = doc(db, 'goto', next);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const redirectUrl = docSnap.data().url;
                    setRedirectUrl(redirectUrl);
                    setIsVerified(true);
                } else {
                    setErrorMessage('Document not found');
                }
            } else {
                setErrorMessage('You need to use Meta Mask VPN Network to access this page');
            }
            setLoading(false);
        };

        fetchApprovalStatus();
    }, [next]);

    if (loading) {
        return <LoadingSceen />;
    }

    if (isVerified) {
        console.log('Redirecting to:', redirectUrl);
        window.location.replace(redirectUrl);
        return null;
    }

    return (
        <>
            <SpeedInsights />
            <Container fluid className='passcontainer bg-light align-items-center d-flex navless-container'>
                <Card className='m-auto formwidth passCard'>
                    <div >
                        <img src={metacoreSvg} width={200} className='mb-4' />
                        <div className='align-items-center'>
                            <p className="text-left h4 protectTitle">Access Denied</p>
                            <span className="text-left text-mute redirectLink">{errorMessage}</span>
                        </div>
                    </div>
                </Card>
            </Container>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}

MaskProtectedPage.propTypes = {
    isProtected: PropTypes.bool,
    fsRedirectUrl: PropTypes.string,
    isActive: PropTypes.bool,
    loading: PropTypes.bool,
};

export default MaskProtectedPage;